import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { parse, stringify } from '../../../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../../../util/routes';
import SectionContainer from '../SectionContainer';
import css from './SectionLandingPageHero.module.css';

import oval from '../../../../assets/images/icons/oval.png';
import leaf from '../../../../assets/images/icons/leaf.png';
import ovalHalf from '../../../../assets/images/icons/oval-half.png';
import spark from '../../../../assets/images/icons/spark.png';
import star1 from '../../../../assets/images/icons/star-1.png';
import star2 from '../../../../assets/images/icons/star-2.png';
import star3 from '../../../../assets/images/icons/star-3.png';
// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionLandingPageHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    config,
    location,
    routeConfiguration,
    history,
    intl,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location?.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {
    if (!!config && isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearcInitialValues();

  const onSearchSubmit = values => {
    const { currentSearchParams } = props;
    const { history, config, routeConfiguration } = props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        // address: search,
        // bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    const pathParams = {
      address: values?.location?.search === '' ? 'Current location' : values?.location?.search
    }
    history.push(createResourceLocatorString('SearchPageWithLocation', routeConfiguration, pathParams, searchParams));
  };

  const search = !!config ? (
    <TopbarSearchForm
      className={classNames(css.searchLink)}
      desktopInputRoot={css.searchInputRoot}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={config}
      isHeroSearch={true}
    />
  ) : null;

  return (
    <div className={css.mainContainer}>
      <div className={css.illustrations}>
        <img className={css.oval} src={oval} alt={'oval'} />
        <img className={css.leaf} src={leaf} alt={'leaf'} />
        <img className={css.ovalHalf} src={ovalHalf} alt={'oval-half'} />
        <img className={css.spark} src={spark} alt={'spark'} />
        <img className={css.star1} src={star1} alt={'star'} />
        <img className={css.star2} src={star2} alt={'star'} />
        <img className={css.star3} src={star3} alt={'star'} />
      </div>
      <SectionContainer
        id={sectionId}
        className={className}
        rootClassName={classNames(rootClassName || css.root)}
        appearance={appearance}
        options={fieldOptions}
      >
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field
              data={description}
              className={classNames(defaultClasses.description, css.description)}
              options={fieldOptions}
            />
            <Field
              data={title}
              className={classNames(defaultClasses.title, css.title)}
              options={fieldOptions}
            />
            {/* <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} /> */}
            {search}
          </header>
        ) : null}
      </SectionContainer>
    </div>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionLandingPageHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionLandingPageHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionLandingPageHero;
