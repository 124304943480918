import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionCustomHero.module.css';

import oval from '../../../../assets/images/icons/oval-2.png';
import flower from '../../../../assets/images/icons/flower.png';
import sun from '../../../../assets/images/icons/sun.png';
import star1 from '../../../../assets/images/icons/star-1.png';
import star2 from '../../../../assets/images/icons/star-2.png';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionCustomHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  return (
    <div className={css.mainContainer}>
      <div className={css.illustrations}>
        <img className={css.oval} src={oval} alt={'oval'} />
        <img className={css.flower} src={flower} alt={'flower'} />
        <img className={css.sun} src={sun} alt={'sun'} />
        <img className={css.star1} src={star1} alt={'star'} />
        <img className={css.star2} src={star2} alt={'star'} />
      </div>
      <SectionContainer
        id={sectionId}
        className={className}
        rootClassName={classNames(rootClassName || css.root)}
        appearance={appearance}
        options={fieldOptions}
      >
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field
              data={title}
              className={classNames(defaultClasses.title, css.title)}
              options={fieldOptions}
            />
            <Field
              data={callToAction}
              className={classNames(defaultClasses.ctaButton, css.ctaButton)}
              options={fieldOptions}
            />
            <Field
              data={description}
              className={classNames(defaultClasses.description, css.description)}
              options={fieldOptions}
            />
          </header>
        ) : null}
      </SectionContainer>
    </div>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCustomHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionCustomHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionCustomHero;
